/*eslint-disable no-useless-escape*/

import Vue from 'vue';
import Vuex from 'vuex';
import { scrollTo } from 'vue-scrollto';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    step: 1,
    isLoading: false,
    modalHotline: false,
    checked: 0,

    user: {
      name: '',
      firstname: '',
      email: '',
      phone: '',
      accusation: '',
      accusationExtended: '',
      document: '',
      driversLicense: '',
      points: '',
      flashed: '',
      insurance: '',
      postFromOffice: '',
      distanceAccusationDistance: '',
      distanceAccusationSpeed: '',
      speedAccusationLocation: '',
      speedAccusationSpeed: '',
      redlightAccusationDamage: '',
      redlightAccusationRisk: '',
      redlightAccusationTime: '',
      id:'',
      casenumber:'',
      refCode:''
    },
  },
  getters: {
    userData: (state) => state.user,
    isLoading: (state) => state.isLoading,
    modalHotline: (state) => state.modalHotline,
    step: (state) => state.step
  },
  
  mutations: {
    updateUser(state, payload) {
      state.user.id = payload;
      state.isLoading = false;
    },
    resetUser(state) {
      state.user = {
        name: '',
        firstname: '',
        email: '',
        phone: '',
        accusation: '',
        accusationExtended: '',
        document: '',
        driversLicense: '',
        points: '',
        flashed: '',
        insurance: '',
        postFromOffice: '',
        distanceAccusationDistance: '',
        distanceAccusationSpeed: '',
        speedAccusationLocation: '',
        speedAccusationSpeed: '',
        redlightAccusationDamage: '',
        redlightAccusationRisk: '',
        redlightAccusationTime: '',
        id:'',
        casenumber:'',
        refCode:''
      };
    },
    updatePhoneNumber(state, payload) {
      state.user.phone = payload;
    },
    updateRefCode(state, payload){
      state.user.refCode = payload;
    },
    createCaseNumber(state) {
      state.user.casenumber = "B" + (""+Math.random()).substring(2,8);
    },
    setLoading(state, payload) {
      state.isLoading = payload;
    },
    setModalHotline(state) {
      state.modalHotline = !state.modalHotline;
    },
    setStep(state, payload) {
      state.step = payload;
    },
    incrementStep (state) {
      // mutate state
      state.step++;
    },
    decrementStep (state) {
      // mutate state
      state.step--;
    },
    setChecked(state, payload) {
      state.checked = payload;
    },
    incrementChecked (state) {
      // mutate state
      state.checked++;
      if (state.step == 1){
        scrollTo('.form-control', 500, { easing: 'ease-in-out' });
      }

    },
    decrementChecked (state) {
      // mutate state
      state.checked--;
    }
  },
  actions: {
    setLoadingState({ commit }, showLoading) {
      commit('setLoading', showLoading);
    },
    setRefCode({ commit }, refCode) {
      commit('updateRefCode', refCode);
    },
  },
  modules: {
  },
});
