<template>
    <main id="app" class="v2">
        <HeaderFormV2 />
        <!-- Section content start-->
        <section class="content-section no-padding">
            <!-- Form section-->
            <div class="form-section no-padding">
                <transition name="fade" mode="out-in" appear>

                    <!-- Frage 1 Tatvorwurf-->
                    <Accusation key="1" v-if="step == 1" @next="next()" />

                    <!-- Frage 2 Erweiterter Tatvorwurf-->
                    <AccusationExtended key="2" v-if="step == 2" @next="next()" />

                    <!-- Frage 3 RSV-->
                    <Insurance key="3" v-if="step == 3" @next="next()" />
                    
                    <!-- Frage 4 Post von Behörde-->
                    <PostFromOffice key="4" v-if="step == 4" @next="next()" />

                    <!-- Frage 5 Geschwindigkeitsverstoß (Wo)-->
                    <SpeedAccusationLocation key="5" v-if="step == 5" @next="next()" />

                    <!-- Frage 5b Geschwindigkeitsverstoß (Wie schnell)-->
                    <SpeedAccusationSpeed key="6" v-if="step == 6" @next="next()" />

                    <!-- Frage 6 Abstandsverstoß (Wie schnell)-->
                    <DistanceAccusationSpeed key="7" v-if="step == 7" @next="next()" />

                    <!-- Frage 6b Abstandsverstoß (Wieviel Abstand)-->
                    <DistanceAccusationDistance key="8" v-if="step == 8" @next="next()" />

                    <!-- Frage 7 Rotlichverstoß (Andere Teilnehmer gefährdet)-->
                    <RedlightAccusationRisk key="9" v-if="step == 9" @next="next()" />

                    <!-- Frage 7b Rotlichverstoß (Etwas beschädigt)-->
                    <RedlightAccusationDamage key="10" v-if="step == 10" @next="next()" />

                    <!-- Frage 7c Rotlichverstoß (Wie lange Ampel rot)-->
                    <RedlightAccusationTime key="11" v-if="step == 11" @next="next()" />

                    <!-- Frage 8 Wieviel Punkte-->
                    <Points key="12" v-if="step == 12" @next="next()" />

                    <!-- Frage 9 Welches Dokument erhalten-->
                    <Document key="13" v-if="step == 13" @next="next()" />

                    <!-- Frage 10 Letzte 12 Monate geblitzt-->
                    <Flashed key="14" v-if="step == 14" @next="next()" />

                    <!-- Frage 11 Führerschein angewiesen-->
                    <DriversLicense key="15" v-if="step == 15" @next="next()" />

                    <!-- Random data loading-->
                    <div class="section index-section random-section" key="333" v-if="step == 16">
                        <div class="section-title">
                            <h3 class="text-center">Ihre Fallauswertung wird nun erstellt</h3>
                        </div>
                        <div class="form-container">
                            <ul class="loading-data">
                                <li v-bind:class="{ loading: item1Loaded }">
                                    <svg class="static">
                                    <use xlink:href="@/assets/images/sprite.svg#icons8-checkmark"></use>
                                    </svg>
                                    <svg class="loading-icon">
                                    <use xlink:href="@/assets/images/sprite.svg#icons8-loading"></use>
                                    </svg>Daten werden verarbeitet
                                </li>
                                <li v-bind:class="{ loading: item2Loaded }">
                                    <svg class="static">
                                    <use xlink:href="@/assets/images/sprite.svg#icons8-checkmark"></use>
                                    </svg>
                                    <svg class="loading-icon">
                                    <use xlink:href="@/assets/images/sprite.svg#icons8-loading"></use>
                                    </svg>Blitzerdatenbank wird gescannt
                                </li>
                                <li v-bind:class="{ loading: item3Loaded }">
                                    <svg class="static">
                                    <use xlink:href="@/assets/images/sprite.svg#icons8-checkmark"></use>
                                    </svg>
                                    <svg class="loading-icon">
                                    <use xlink:href="@/assets/images/sprite.svg#icons8-loading"></use>
                                    </svg>Mögliche Strafen werden ermittelt
                                </li>
                                <li v-bind:class="{ loading: item4Loaded }">
                                    <svg class="static">
                                    <use xlink:href="@/assets/images/sprite.svg#icons8-checkmark"></use>
                                    </svg>
                                    <svg class="loading-icon">
                                    <use xlink:href="@/assets/images/sprite.svg#icons8-loading"></use>
                                    </svg>Messfehler werden gesucht
                                </li>
                                <li v-bind:class="{ loading: item5Loaded }">
                                    <svg class="static">
                                    <use xlink:href="@/assets/images/sprite.svg#icons8-checkmark"></use>
                                    </svg>
                                    <svg class="loading-icon">
                                    <use xlink:href="@/assets/images/sprite.svg#icons8-loading"></use>
                                    </svg>Prüfung wird vorbereitet
                                </li>
                            </ul>
                        </div>
                    </div>

                    <!-- Frage 12 Persönliche Daten-->
                    <section key="16" v-if="step == 17">
                    <ValidationObserver v-slot="{ invalid }">
                        <form @submit.prevent="sendData">
                            <div class="section index-section" key="personalData">
                                <div class="section-title">
                                    <h3>Fast fertig! Wohin darf ich Ihre Fallauswertung senden?</h3>
                                </div>
                                <div class="text-center">
                                    <p class="green-color filled">
                                    <svg>
                                        <use xlink:href="@/assets/images/sprite.svg#icons8-checkmark"></use>
                                    </svg>Kostenlos und unverbindlich
                                    </p>
                                </div>
                                <div class="form-container form-data">
                                    <div class="input-row two-input">
                                        <div class="wrap-input">
                                            <ValidationProvider mode="eager" name="vorname" rules="required" v-slot="{ errors }">
                                                <input type="text" v-model="user.firstname"><span class="label-input" v-bind:class="{ filled : user.firstname.length >= 1 }">Ihr Vorname *</span>
                                                <p class="form-error">{{ errors[0] }}</p>
                                            </ValidationProvider>
                                        </div>
                                        <div class="wrap-input">
                                            <ValidationProvider mode="eager" name="nachname" rules="required" v-slot="{ errors }">
                                                <input type="text" v-model="user.name"><span class="label-input" v-bind:class="{ filled : user.name.length >= 1 }">Ihr Nachname *</span>
                                                <p class="form-error">{{ errors[0] }}</p>
                                            </ValidationProvider>
                                        </div>
                                    </div>
                                    <div class="input-row">
                                        <div class="wrap-input">
                                            <ValidationProvider mode="eager" name="email" rules="required|email" v-slot="{ errors }">
                                                <input type="email" v-model="user.email"><span class="label-input" v-bind:class="{ filled : user.email.length >= 1 }">Ihre E-Mail Adresse *</span>
                                                <p class="form-error">{{ errors[0] }}</p>
                                            </ValidationProvider>
                                        </div>
                                    </div>
                                    <div class="input-row">
                                        <div class="wrap-input">
                                            <MazPhoneNumberInput
                                                v-model="phone"
                                                type="tel"
                                                default-country-code="DE"
                                                :no-country-selector=true
                                                :only-countries="['DE']"
                                                placeholder="Ihre Telefonnummer*"
                                                @update="phonenumberResult = $event"
                                                :translations="{
                                                    countrySelectorLabel: 'Land',
                                                    countrySelectorError: 'Bitte Land wählen',
                                                    phoneNumberLabel: 'Ihre Telefonnummer',
                                                    example: 'Beispiel:'
                                                }"
                                            />
                                            <!--<ValidationProvider mode="eager" name="phone" rules="required" v-slot="{ errors }">
                                                <input type="phone" v-model="phone"><span class="label-input" v-bind:class="{ filled : phone.length >=1 }">Ihre Telefonnummer *</span>
                                                <p class="form-error">{{ errors[0] }}</p>
                                            </ValidationProvider> -->
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-control" key="18">
                                <transition name="fade">
                                    <button type="submit" :disabled="invalid == true || formSubmitted == true" class="next submit"><span v-if="isLoading == true"></span>Kostenlose Auswertung anfordern</button>
                                    
                                </transition>
                            </div>
                            
                        </form>
                    </ValidationObserver>
                    </section>
                    <!-- Result-->
                    <div class="section index-section result-section" key="120" v-if="step == 18">
                        <div class="section-title">
                        <div class="icon-document"><img src="@/assets/images/icons-check-document-active.png"></div>
                        <h3>Anfrage erfolgreich eingegangen. <br> Ihre Fallnummer lautet {{user.casenumber}}</h3>
                        <p>Vielen Dank für Ihre Anfrage! Ihr Fall ist nun in Bearbeitung und wir werden uns umgehend bei Ihnen melden.</p>
                        </div>
                    </div>
                </transition>
                <div class="form-control" v-if="step <= 15" key="18">
                <p v-show="step > 1"><a class="prev" href="#" v-on:click.prevent="prev()">
                    <svg>
                    <use xlink:href="@/assets/images/sprite.svg#right-arrow"></use>
                    </svg>Zurück</a>
                </p>
                </div>
            </div>
        </section>
        <Footer />
    </main>
</template>
<script>
/*eslint-disable no-useless-escape*/
import { MazPhoneNumberInput } from 'maz-ui';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { extend } from 'vee-validate';
import { required } from 'vee-validate/dist/rules';

import axios from 'axios';
import { mapState, mapMutations } from 'vuex';

import { logger } from '@/common/logger';

// Implement own mail regex, due to not correctly identify wrong mails like 'mail@-example.de'
extend('email', value => {
    const EMAILREG = /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)+$/;
    
    if (EMAILREG.test(value)) {
        return true;
    }
    return 'Bitte eine gültige E-Mail-Adresse eingeben';
});

// Override the default message.
extend('required', {
  ...required,
  message: 'Dies ist ein Pflichtfeld'
});

export default {
    name:'FormV2',
    components: {
        ValidationProvider,
        ValidationObserver,
        MazPhoneNumberInput,
        HeaderFormV2: () => import('./HeaderFormV2'),
        Footer: () => import('./../Footer'),
        Accusation: () => import('./questions/Accusation'),
        AccusationExtended: () => import('./questions/AccusationExtended'),
        DistanceAccusationDistance: () => import('./questions/DistanceAccusationDistance'),
        DistanceAccusationSpeed: () => import('./questions/DistanceAccusationSpeed'),
        Document: () => import('./questions/Document'),
        DriversLicense: () => import('./questions/DriversLicense'),
        Flashed: () => import('./questions/Flashed'),
        Insurance: () => import('./questions/Insurance'),
        Points: () => import('./questions/Points'),
        PostFromOffice: () => import('./questions/PostFromOffice'),
        RedlightAccusationDamage: () => import('./questions/RedlightAccusationDamage'),
        RedlightAccusationRisk: () => import('./questions/RedlightAccusationRisk'),
        RedlightAccusationTime: () => import('./questions/RedlightAccusationTime'),
        SpeedAccusationLocation: () => import('./questions/SpeedAccusationLocation'),
        SpeedAccusationSpeed: () => import('./questions/SpeedAccusationSpeed'),
    },
    data() {
        return {
            progressWidth: 5,
            phone: null,
            phonenumberResult: null,
            formSubmitted: false,
            item1Loaded: true,
            item2Loaded: false,
            item3Loaded: false,
            item4Loaded: false,
            item5Loaded: false,
        };
    },
    computed: mapState({
        user: (state) => state.user,
        step: (state) => state.step,
        isLoading: (state) => state.isLoading,
    }),
    methods:{
        ...mapMutations([
            'incrementChecked',
            'decrementChecked',
            'setChecked',
            'setStep',
            'incrementStep',
            'decrementStep',
            'setLoading',
            'updatePhoneNumber',
            'createCaseNumber',
            'resetUser'
        ]),
        fakeResult(){
            setTimeout(
            () =>  {
                this.item1Loaded = true;
            } , 700);
            setTimeout(
            () =>  {
                this.item2Loaded = true;
            } , 1400);
            setTimeout(
            () =>  {
                this.item3Loaded = true;
            } , 2100);
            setTimeout(
            () =>  {
                this.item4Loaded = true;
            } , 2800);
            setTimeout(
            () =>  {
                this.item5Loaded = true;
                this.next();
            } , 3500);
        },
        prev() {
            if (this.step == 12) {
                switch (this.user.accusation) {
                    case 'Geschwindigkeitsverstoß':
                        this.setStep(6);
                        break;
                    case 'Abstandsverstoß':
                        this.setStep(8);
                        break;
                        
                    case 'Rotlichtverstoß':
                        this.setStep(11);
                        break;
                
                    default:
                        this.setStep(4);
                        break;
                }
            } else if (this.step == 9){
                this.setStep(4);
            } else if (this.step == 7){
                this.setStep(4);
            } else if (this.step == 3){
                if (this.user.accusation == 'Handy / Drogen / Parken') {
                    this.setStep(2);
                }else{
                    this.setStep(1);
                }
            } else {
                this.decrementStep();
            }
        },
        next() {
            this.setLoading(true);
            setTimeout(
            () =>  {
                /*For the next and prev navigation and the visibility of the next button,
                we have to check if the next question is already filled. If yes, show next button.
                */
                switch (this.step) {
                    // Tatvorwurf
                    case 1:
                        if (this.user.accusation == 'Handy / Drogen / Parken') {
                            this.setStep(2);
                        }else{
                            this.setStep(3);
                            this.user.accusationExtended = '';
                        }
                        break;
                    // Tatvorwurf Erweitert
                    case 2:
                        this.incrementStep();
                        break;
                    // RSV    
                    case 3:
                        this.incrementStep();
                        break;
                    // Post von Behörde    
                    case 4:
                        switch (this.user.accusation) {
                            case 'Geschwindigkeitsverstoß':
                                // clear values for other accusations
                                this.user.distanceAccusationDistance = '';
                                this.user.distanceAccusationSpeed = '';
                                this.user.redlightAccusationRisk = '';
                                this.user.redlightAccusationDamage = '';
                                this.user.redlightAccusationTime = '';

                                this.setStep(5);
                                break;
                            case 'Abstandsverstoß':
                                // clear values for other accusations
                                this.user.speedAccusationLocation = '';
                                this.user.speedAccusationSpeed = '';
                                this.user.redlightAccusationRisk = '';
                                this.user.redlightAccusationDamage = '';
                                this.user.redlightAccusationTime = '';

                                this.setStep(7);
                                break;
                                
                            case 'Rotlichtverstoß':
                                // clear values for other accusations
                                this.user.speedAccusationLocation = '';
                                this.user.speedAccusationSpeed = '';
                                this.user.distanceAccusationDistance = '';
                                this.user.distanceAccusationSpeed = '';

                                this.setStep(9);
                                break;
                        
                            default:
                                // clear values for other accusations
                                this.user.speedAccusationLocation = '';
                                this.user.speedAccusationSpeed = '';
                                this.user.distanceAccusationDistance = '';
                                this.user.distanceAccusationSpeed = '';
                                this.user.redlightAccusationRisk = '';
                                this.user.redlightAccusationDamage = '';
                                this.user.redlightAccusationTime = '';

                                this.setStep(12);
                                break;
                        }
                        break;
                    // Geschwindigkeitsverstoß Ort    
                    case 5:
                        this.incrementStep();
                        break;
                    // Geschwindigkeitsverstoß Geschwindigkeit    
                    case 6:
                        this.setStep(12);
                        break;
                    // Abstandsverstoß (wie schnell)
                    case 7:
                        this.incrementStep();
                        break;
                    // Abstandsverstoß (wieviel Abstand)
                    case 8:
                        this.setStep(12);
                        break;
                    // Rotlichverstoß (Andere Teilnehmer gefährdet)
                    case 9:
                        this.incrementStep();
                        break;
                    // Rotlichverstoß (Etwas beschädigt)
                    case 10:
                        this.incrementStep();
                        break;
                    // Rotlichverstoß (Wie lange Ampel rot)
                    case 11:
                        this.incrementStep();
                        break;
                    // Wieviel Punkte
                    case 12:
                        this.incrementStep();
                        break;
                    // Welches Dokument
                    case 13:
                        this.incrementStep();
                        break;
                    // Letzte 12 Monate geblitzt
                    case 14:
                        this.incrementStep();
                        break;
                    // Führerschein angewiesen
                    // Persönliche Daten
                    case 15:
                        this.incrementStep();
                        this.fakeResult();
                        break;
                    case 16:
                    default:
                        this.incrementStep();
                        break;
                }
                this.progressWidth = 100 / 17 * this.step; // 17 - it's count of question in form
                this.setLoading(false);
            } , 500);
        },
        sendData() {
            if (!this.phonenumberResult.isValid) {
                return;
            }
            //TODO: gtm tracking
            if (this.$gtm.enabled()) {
                window.dataLayer?.push({
                    event: "formSubmission",
                    formType: "Lead",
                    formPosition: "Center"
                });
            }

            this.formSubmitted = true;
            this.$store.dispatch('setLoadingState', true);

            //remove non-numbers from string
            this.updatePhoneNumber(this.phone);
            
            //Create casenumber and save to user in vuex store.
            this.createCaseNumber();

            //send user data to lead service and get created lead id
            axios({
                method: 'post',
                data: {
                    user: this.user
                },
                url: `${process.env.VUE_APP_API_LEADS_URL}/leads/insert`,
                headers: {
                'Content-Type': 'application/json',
                },
            }).then((response) => {
                var leadId = response.data.leadId; // Extract the leadId string
                console.log('Received leadId:', leadId);

                if (leadId != '') {
                    /*redirect to upload service
                    lid = leadid, fn = casenumber, 
                    fwd is used to guide the user through all followed processes
                    pr = product
                    */
                    window.location= `${process.env.VUE_APP_FRONTEND_UPLOAD_URL}?lid=${leadId}&fn=${this.user.casenumber}&fwd=true&pr=BUK`;
                } else {
                    logger.error('Fehler bei Übertragung an Zoho. Fallnummer:{Fallnummer};Name:{Name}, {Vorname};E-Mail: {Email};Tel.: {Phone}', this.user.casenumber, this.user.name, this.user.firstname, this.user.email, this.user.phone);
                    this.setStep(18);
                    this.formSubmitted = false;
                }
                this.$store.dispatch('setLoadingState', false);
            })
            .catch((error) => {
                logger.error(error);
                logger.error('Fehler bei Übertragung an Zoho. Fallnummer:{Fallnummer};Name:{Name}, {Vorname};E-Mail: {Email};Tel.: {Phone}', this.user.casenumber, this.user.name, this.user.firstname, this.user.email, this.user.phone);
                this.$store.dispatch('setLoadingState', false);
                this.formSubmitted = false;
            });
        }
    },
    created() {
        this.resetUser();
        this.setStep(1);
        if (this.$route.query.ref !== undefined || this.$route.query.ref !== '') {
            this.$store.dispatch('setRefCode', this.$route.query.ref);
        }
    },
}
</script>