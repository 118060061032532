<template>
  <router-view />
</template>
<style lang="scss">
  @import "assets/sass/styles";
</style>
<script>

export default {
  name: 'App'
}
</script>
