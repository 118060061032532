<template>
    <main id="app" class="v2">
        <HeaderV2 />
        <!-- Section content start-->
        <section class="content-section no-padding">
            <!-- Form section-->
            <div class="index-section">
                <div class="section">
                    <div class="section-title">
                        <div class="author"><img src="@/assets/images/rabier.png"></div>
                        <h3>"Jeder zweite Bußgeldbescheid ist fehlerhaft. Ist es Ihrer auch?"</h3>
                        <p>Mein Name ist Alexander Biernacki. Gemeinsam prüfen wir in wenigen Minuten, ob sich ein <b>Einspruch</b> gegen Bußgeld, Punkte oder Fahrverbot lohnt.</p>
                    </div>
                    <div class="form-container">
                        <div class="notice">Die Prüfung ist <u>kostenlos</u> und <u>unverbindlich</u> !</div>
                        <router-link to="/v2/form" class="next">Kostenlose Prüfung starten</router-link>
                        <div class="header-partners">
                            <p>Bekannt aus Rundfunk und TV</p>
                            <div class="partners-img"><img src="@/assets/images/welt_testimonial.png"><img src="@/assets/images/heise_testimonial.png"><img src="@/assets/images/br_testimonial.png"></div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <Footer />
    </main>
</template>
<script>
export default {
    components: {
        HeaderV2: () => import('./HeaderV2'),
        
        Footer: () => import('./../Footer'),
    },
    created() {
        if (this.$route.query.ref !== undefined || this.$route.query.ref !== '') {
            this.$store.dispatch('setRefCode', this.$route.query.ref);
            console.log(this.$route.query.ref);
        }
    },
}
</script>