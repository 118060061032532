/* eslint-disable no-unused-vars, max-len, consistent-return */
import Vue from 'vue';
import VueRouter from 'vue-router';
import Index from '../components/Index.vue';
import IndexV2 from '../components/v2/IndexV2.vue';
import FormV2 from '../components/v2/FormV2.vue';
import IndexV3 from '../components/v3/IndexV3.vue';
import Imprint from '../components/Imprint.vue';
import Privacy from '../components/Privacy.vue';
import ErrorPage from '../components/ErrorPage.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Index',
    component: Index,
    meta: {
      title: 'Bussgeldkanzlei.de - Hilfe bei Bußgeldbescheid, Fahrverbot &amp; Verkehrsdelikten',
    },
  },
  {
    path: '/v2',
    name: 'IndexV2',
    component: IndexV2,
    meta: {
      title: 'Bussgeldkanzlei.de - Hilfe bei Bußgeldbescheid, Fahrverbot &amp; Verkehrsdelikten',
    },
  },
  {
    path: '/v2/form',
    name: 'FormV2',
    component: FormV2,
    meta: {
      title: 'Bussgeldkanzlei.de - Hilfe bei Bußgeldbescheid, Fahrverbot &amp; Verkehrsdelikten',
    },
  },
  {
    path: '/v3',
    name: 'IndexV3',
    component: IndexV3,
    meta: {
      title: 'Bussgeldkanzlei.de - Hilfe bei Bußgeldbescheid, Fahrverbot &amp; Verkehrsdelikten',
    },
  },
  {
    name: 'imprint',
    path: '/impressum',
    component: Imprint,
    meta: {
      title: 'Bussgeldkanzlei.de - Impressum',
    },
  },
  {
    name: 'privacy',
    path: '/datenschutz',
    component: Privacy,
    meta: {
      title: 'Bussgeldkanzlei.de - Datenschutz',
    },
  },
  {
    name: 'ErrorPage',
    path: '*',
    component: ErrorPage,
    meta: {
      title: 'Bussgeldkanzlei.de - Seite nicht gefunden',
    },
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

// This callback runs before every route change, including on page load.
router.beforeEach((to, from, next) => {
  // This goes through the matched routes from last to first, finding the closest route with a title.
  // eg. if we have /some/deep/nested/route and /some, /deep, and /nested have titles, nested's will be chosen.
  const nearestWithTitle = to.matched.slice().reverse().find((r) => r.meta && r.meta.title);

  // Find the nearest route element with meta tags.
  const nearestWithMeta = to.matched.slice().reverse().find((r) => r.meta && r.meta.metaTags);
  const previousNearestWithMeta = from.matched.slice().reverse().find((r) => r.meta && r.meta.metaTags);

  // If a route with a title was found, set the document (page) title to that value.
  if (nearestWithTitle) document.title = nearestWithTitle.meta.title;

  // Remove any stale meta tags from the document using the key attribute we set below.
  Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map((el) => el.parentNode.removeChild(el));

  // Skip rendering meta tags if there are none.
  if (!nearestWithMeta) return next();

  // Turn the meta tag definitions into actual elements in the head.
  nearestWithMeta.meta.metaTags.map((tagDef) => {
    const tag = document.createElement('meta');

    Object.keys(tagDef).forEach((key) => {
      tag.setAttribute(key, tagDef[key]);
    });

    // We use this to track which meta tags we create, so we don't interfere with other ones.
    tag.setAttribute('data-vue-router-controlled', '');

    return tag;
  })
  // Add the meta tags to the document head.
    .forEach((tag) => document.head.appendChild(tag));

  next();
});

export default router;